import React from "react"
import axios from "axios"
import $ from "jquery"

class ContactFrom extends React.Component {
  state = {
    dittNavn: "",
    postNummer: "",
    email: "",
    telefon: "",
    message: "",
    checked: false,
    sent: false,
    buttonText: "Sende",
  }

  resetForm = () => {
    this.setState({
      dittNavn: "",
      postNummer: "",
      email: "",
      telefon: "",
      message: "",
      checked: false,
      buttonText: "Takk for din henvendelse. Vi kontakter deg snart.",
    })
  }

  componentDidMount = () => {
    $("form input, form textarea").focusin(function () {
      $(this).parent().addClass("fill")
    })

    $("form input, form textarea").focusout(function () {
      var inputVal = $(this).val().length
      if (inputVal > 0) {
        $(this).parent().addClass("fill")
      } else {
        $(this).parent().removeClass("fill")
      }
    })
  }

  formSubmit = e => {
    e.preventDefault()

    this.setState({
      buttonText: "...sende",
    })

    let formData = new FormData()

    formData.set("dittNavn", this.state.dittNavn)
    formData.set("postNummer", this.state.postNummer)
    formData.set("email", this.state.email)
    formData.set("message", this.state.message)
    formData.set("telefon", this.state.telefon)

    axios({
      method: "post",
      url: "https://wp.nor-netting.no/wp-json/contact-form-7/v1/contact-forms/5/feedback/",
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(res => {
        this.setState({ sent: true }, this.resetForm())
        $("form input, form textarea").parent().removeClass("fill")
      })
      .catch(err => {
        console.log(err)
      })
  }

  render() {
    return (
      <form onSubmit={e => this.formSubmit(e)}>
        {/* eslint-disable jsx-a11y/label-has-for */}
        {/* eslint-disable jsx-a11y/no-onchange */}
        <div className="row">
          <div className="col-md-6">
            <div className="input-wrapper">
              <label htmlFor="dittNavn">Ditt navn</label>
              <input
                type="text"
                id="dittNavn"
                name="dittNavn"
                autoComplete="off"
                value={this.state.dittNavn}
                onChange={e => this.setState({ dittNavn: e.target.value })}
              />
            </div>
            <div className="input-wrapper">
              <label htmlFor="postNummer">Postnummer*</label>
              <input
                type="text"
                id="postNummer"
                name="postNummer"
                autoComplete="off"
                value={this.state.postNummer}
                onChange={e => this.setState({ postNummer: e.target.value })}
                required
              />
            </div>

            <div className="input-wrapper">
              <label htmlFor="email">E-postadresse*</label>
              <input
                type="email"
                id="email"
                name="email"
                autoComplete="off"
                value={this.state.email}
                onChange={e => this.setState({ email: e.target.value })}
                required
              />
            </div>
            <div className="input-wrapper">
              <label htmlFor="telefon">Telefon</label>
              <input
                type="tel"
                id="telefon"
                name="telefon"
                autoComplete="off"
                value={this.state.telefon}
                onChange={e => this.setState({ telefon: e.target.value })}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="input-wrapper">
              <label htmlFor="message">Skriv din forespørsel her</label>
              <textarea
                type="text"
                id="message"
                name="message"
                autoComplete="off"
                value={this.state.message}
                onChange={e => this.setState({ message: e.target.value })}
                rows="5"
              ></textarea>
            </div>
            <span className="wpcf7-acceptance">
              <span className="wpcf7-list-item">
                <label>
                  <input
                    type="checkbox"
                    checked={this.state.checked}
                    onChange={e =>
                      this.setState({ checked: !this.state.checked })
                    }
                  />
                  <span className="wpcf7-list-item-label">
                    Ja, takk! Jeg ønsker å motta tips pr e-post fra våre
                    eksperter hver måned.
                  </span>
                </label>
              </span>
            </span>
            <button type="submit" className="wpcf7-submit">
              {this.state.buttonText}
            </button>
          </div>
        </div>
      </form>
    )
  }
}

export default ContactFrom
